import React, { useEffect, useState } from 'react';
import Movie from './components/Movie';


//TODO - Move this in env file
const api = "https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=84c972c4ca4717120132568ff52f2b74&page=1";
const search = "https://api.themoviedb.org/3/search/movie?&api_key=84c972c4ca4717120132568ff52f2b74&query=";

function App() {

  const [movies, setMovies] = useState([]);
  const [searchString, setSearchString] = useState('');

  // Use effect scheme:
  useEffect(() => {
    getMovies(api);
  }, []);

  const getMovies = (apiKey) => {

    // Fetching and setting data from API
    fetch(apiKey)
      .then(res => res.json())
      .then(data => {
        setMovies(data.results);
        // console.log(data);
      });
  }

  const searchAction = (e) => {
    e.preventDefault();

    // Fetching searching data from API
    if (searchString) {
      getMovies(search + searchString);
      // Empty the search box:
      setSearchString('');
    }

  };

  const searchOnChange = (e) => {
    setSearchString(e.target.value);
  };

  return (
    <React.Fragment>
      <header>
        <div className="logo"></div>
        <form onSubmit={searchAction}>
          <input
            className="search-btn"
            type="search"
            placeholder="Search..."
            value={searchString}
            onChange={searchOnChange}
          />
        </form>
      </header>
      <div className="movie-container">
        {movies.length > 0 && movies.map(movie => (
          <Movie key={movie.id} {...movie} />
        ))}
      </div>
    </React.Fragment>
  )
}

export default App;
